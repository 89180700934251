import styled from "styled-components";



export const MemberBox = styled.div`
  min-height: calc(100vh - 20px); /* Adjusted for margin */
  
  width: calc(100% - 20px);
  margin: 10px;
  background: #FFFFFF;
  color : #000000;
  border-radius: 16px;
  text-align : center;
`;



export const SideBarBox = styled.div`
// display: flex;
flex-direction: column;
align-items: flex-start;
padding: 20px;
margin-bottom: 20px;
padding-right : 20px;
width: 100%;
margin-right:10px;
background: rgba(255, 255, 255, 0.1);
border-radius: 10px;
flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;

`;

export const SidebarTextStyle = styled.span`
/* Account details */


font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 20px;
margin-buttom: 10px;
/* identical to box height */
display: flex;
align-items: center;

color: #FFFFFF;
`;

export const SidebarTextActive = styled.span`
/* Primary */

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
padding: 10px;
gap: 4px;

height: 40px;

background: rgba(255, 255, 255, 0.15);
border-radius: 10px;

/* Inside auto layout */
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
`;

export const SidebarTextNotActive = styled.span`
/* Primary */

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
padding: 10px;
gap: 4px;

height: 40px;


/* Inside auto layout */
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
`;




export const UserInfoTextStyle = styled.span`
/* Feature label goes h */
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;

color: #4E2D92;

`;


export const IndicatorPageBoxStyle = styled.span`
/* iconfinder_menu_2561466 1 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px 20px;
gap: 5px;
height: 44px;
left: 0px;
top: 0px;

background: #EDEAF4;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);


`;

export const IndicatorPageBoxTitleStyle = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 20px;
/* identical to box height */
display: flex;
align-items: center;
color: #564F5C;
/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;

`;

export const IconColor = "#564F5C"