import React from "react";
import Grid from "@material-ui/core/Grid";
import GetDetailsBox from "./DetailBox";






function TutorBox({ tutors }) {
    return (
        <React.Fragment>
            <Grid container spacing={1} justifyContent="center">
            {tutors.map(tutor => (
                <Grid item key={tutor.id} xs={12} sm={12} md={4} lg={3} xl={2} >
<GetDetailsBox key={tutor.id} color={tutor.color} name={tutor.name} photo={tutor.photo} description = {tutor.description} />
                </Grid>
                
            ))}
            </Grid>

        </React.Fragment>
    );
}



export default function ResponsiveTutorBox({ tutors }) {
    return (
        <Grid container spacing={2} justifyContent="center" key='tutors-grid-data'>
        <Grid item xs={12} sm={12} md={12} lg={12} >
            <TutorBox tutors={tutors}/>
        </Grid>
        </Grid>
    );
    }
    