import React from 'react';
import AppRouter from './Router';
import { StrictMode } from 'react';
import { Provider } from "react-redux";
import store from "./store";
 import useAuthState from './utility/useAuthStatus';
//import useAuthState from './utility/useAuthStatus';
function App() {
  const { isAuthenticated, authStateChecking } = useAuthState()
  if(authStateChecking) {
    return <p>Loading....</p>
  }
  return (
    <div className="App">
      
      <AppRouter isAuth={isAuthenticated} />
    </div>
  );
}


function ReduxApp(){
  return (
<Provider store={store}>
  <App/>
</Provider>
  );
}
export default ReduxApp;