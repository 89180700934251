import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const SideTitle = styled.span`
/* Unlimited */
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 60px;
padding-left: 10px;
color: #FFFFFF;
`;


export const Sidesubtitle = styled.span`
/* Feature label goes h */
height: 23px;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 22px;
/* identical to box height */
color: #FFFFFF;
`;


export const StyledGridItem = styled(Grid)`
  padding-bottom: 20px; /* Adjust margin as needed */
`;

export const StyledGridItemTitle = styled(Grid)`
  padding-bottom: 30px; /* Adjust margin as needed */
`;

export const TutorNamesStyle = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;
margin-top : 10px;
line-height: 60px;
color: #564F5C;
`;

export const TutorDescriptionStyle = styled.div`
/* Try it as long as yo */
font-family: 'Poppins';
font-style: normal;
padding-right : 40px;
font-weight: 400;
font-size: 15px;
line-height: 22px;
margin-top : 2px;
color: #564F5C;
mix-blend-mode: normal;
opacity: 0.4;

`;

export const MembershipPlanTitleStyle = styled.div`
/* Free */
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 60px;
/* identical to box height */
text-align: start;
`;

export const MembershipPlanSubtitleStyle = styled.div`

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;
mix-blend-mode: normal;
opacity: 0.4;

`;

export const MembershipPlanFeaturesStyle = styled.div`
display: inline-block;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
padding-bottom: 17px;
font-size: 15px;
line-height: 22px;
`;