import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const GradientBackground = styled.div`
  background: linear-gradient(45deg, #180D3E 30%, #483881 90%);
  border: 0;
  border-radius: 3px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  color: white;
  min-height: 100vh; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GradientBackgroundColorcode = "linear-gradient(45deg, #180D3E 30%, #483881 90%)";

export const GradientBackgroundMember = styled.div`
  border: 0;
  border-radius: 3px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  color: white;
  min-height: 100vh; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledGrid = styled(Grid)`
  && {
    margin: 20px; /* Adjust margin as needed */
    padding-top: 20px; /* Adjust padding as needed */
  }
`;