import React from "react";
import Paper from "@material-ui/core/Paper";
import {
	MembershipPlanTitleStyle,
	MembershipPlanSubtitleStyle,
	MembershipPlanFeaturesStyle,
} from "../style/TextStylesTitle";
import { LabelStyleButton } from "../style/LoginStyles";
import { ListItem, PackagemonthStyle } from "../style/CheckboxStyle";
import CheckIcon from "@mui/icons-material/Check";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function PaidPlanDetails({ name, description, price, features }) {
	const isLG = useMediaQuery('(min-width: 1280px)'); 
	return (
		<Box
			style={{
				// display: "inline-block",
				paddingLeft: "40px",
				paddingTop: "20px",
				paddingRight: "40px",
				paddingBottom: "30px",
				minHeight: "504px",
				background: "#4E2D92",
				borderRadius: "16px",
				textAlign: "start",
			}}
			p={2}
		>
			<Grid
				container
				spacing={1}
				rowspacing={1}
				direction="row"
				justifyContent="center"
				alignItems="center"
				style={{ marginBottom: "12px" }}
				>
					<Grid item xs={12} sm={6} md={12} lg={6}>
						<MembershipPlanTitleStyle style={{ color: "#FFFFFF" }}>
							{name}
						</MembershipPlanTitleStyle>
						<MembershipPlanSubtitleStyle
							style={{ color: "#FFFFFF", paddingBottom: "57px" }}
						>
							{description}
						</MembershipPlanSubtitleStyle>
					</Grid>
					<Grid item xs={12} sm={6} md={12} lg={6}>
						<MembershipPlanTitleStyle style={{ color: "#FFFFFF", textAlign: isLG ? "end" : "start"}}>
							{price}
							<PackagemonthStyle>/mo</PackagemonthStyle>
							<MembershipPlanSubtitleStyle
							style={{ color: "#FFFFFF", paddingBottom: "57px" }}
						>
						</MembershipPlanSubtitleStyle>
						</MembershipPlanTitleStyle>
					</Grid>
				</Grid>
				<Box bgcolor="red"></Box>
				<Grid
				container
				spacing={0}
				direction="row"
				justifyContent="flex-start"
				alignItems="baseline"
			>
				{features.map((feature, index) => (
					<Grid key={index} item xs={12} sm={6}>
						<ListItem key={index}>
							<CheckIcon
								style={{
									color: "#ffffff",
									mixBlendMode: "normal",
									opacity: "0.5",
									paddingRight: "12px",
								}}
							/>
							<MembershipPlanFeaturesStyle style={{ color: "#ffffff" }}>
								{feature}
							</MembershipPlanFeaturesStyle>
						</ListItem>
					</Grid>
				))}
			</Grid>
			<button
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					padding: "20px",
					marginBottom: "20px",
					gap: "4px",
					height: "64px",
					borderRadius: "10px",
					flex: "none",
					background: "#ffffff",
					order: 0,
					marginTop: "40px",
					flexGrow: 0,
					border: "none", // Remove default button border
					color: "white", // Text color
					fontSize: "16px", // Text size
					cursor: "pointer", // Change cursor on hover
					borderRadius: "10px",
					border: "1px solid #564F5C",
				}}
				// Optional onClick event handler
			>
				<LabelStyleButton style={{ color: "#564F5C" }}>
					Upgrade to Premium
				</LabelStyleButton>
			</button>
		</Box>
	);
}

export default PaidPlanDetails;
