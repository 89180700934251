import process from 'process'


const HostName = "https://gpt4hsc.com";

export const TutorEndpoint = `${HostName}/api/tutor/`;
export const MembershipDataEndpoint = `${HostName}/api/membership/`;
export const CSRFTokenEndpoint = `${HostName}/api/csrf/`;
export const LoginEndpoint = `${HostName}/api/login/`;
export const RegisterEndpoint = `${HostName}/api/register/`;
export const LogoutEndpoint = `${HostName}/api/logout/`;
export const ProfileEndpoint = `${HostName}/api/profile/`;
export const ResetPasswordEndpoint = `${HostName}/api/resetpasswd/`;
export const UserInfoEndpoint = `${HostName}/api/userinfo/`;
export const TutorSelectionEndpoint = `${HostName}/api/tutorselection/`;
