import { ProfileEndpoint } from '../components/Endpoints';
import { useState, useEffect } from 'react';
import Cookie from 'js-cookie'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useAuthState() {
    const dispatch = useDispatch()
    const [authStateChecking, setAuthStateChecking] = useState(true);
    const [isAuthenticated, updateAuthState] = useState(false)
    const signInState = useSelector((state) => ({
        loading: state?.signin?.loading ?? false,
        data: state?.signin?.data ?? null,
        error: state?.signin?.error ?? null
    }));
    
    useEffect(() => {
        const checkAuthState = async () => {
            try {
                // Get the jwt token stored in cookie
                const authToken = Cookie.get('authtoken');
                if (!authToken) {
                    updateAuthState(false);
                    setAuthStateChecking(false);
                    return;
                }

                // Call the auth check api which will return user object if authenticated
                const response = await fetch(ProfileEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });

                if (response.ok) {
                    const userData = await response.json();
                    updateAuthState(true);
                    dispatch({ type: `SIGN_IN_FULFILLED`, payload: userData });
                } else {
                    updateAuthState(false);
                }
            } catch (error) {
                console.error('Error checking authentication state:', error);
                updateAuthState(false);
            } finally {
                setAuthStateChecking(false);
            }
        };

        checkAuthState();
    }, [dispatch]);

    useEffect(() => {
        if (!signInState.loading && signInState.data?.token) {
            updateAuthState(true);
        }
    }, [signInState.data]);

    return { isAuthenticated, authStateChecking };
}
