import React from "react";
import { MemberBox } from "./MemberStyle";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from 'react-redux';
import DeleteToken from "../logout/logout"
import {
	LabelStyle,
	LabelStyleLink,
	LabelStyleButton,
	LabelStyleFeatured,
	LabelStyleFeaturedLink,
	HorizantalLine,
	LabelHintsTextStyle,
} from "../style/LoginStyles";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {ResetPasswordEndpoint, CSRFTokenEndpoint} from "../Endpoints";
import { useHistory } from 'react-router-dom';
import { LoginRoute } from "../routerpaths";
import { signInRejected } from "../../actions";
function ChangePasswordBox() {
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		const getCsrfToken = async () => {
			try {
				await axios.get(CSRFTokenEndpoint, {
					withCredentials: true,
				});
			} catch (error) {
				console.error("Error fetching CSRF token:", error);
			}
		};

		getCsrfToken();
	}, []);
	const [current_password, setcurrent_password] = useState("");
	const [new_password, setnew_password] = useState("");
	const [confirm_password, setconfirm_password] = useState("");
	const [errorcurrent_password, setErrorcurrent_password]  = useState("");
	const [errornew_password, setErrornew_password]  = useState("Must contain at least one capital later, one number and a special character");
	const [errorconfirm_password, setErrorconfirm_password]  = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const resetPasswordHandler = async ( e) => {
		e.preventDefault();
		if (current_password === "" || new_password === "" || confirm_password === "") {
			setErrorcurrent_password("Current password is required");
			setErrornew_password("New password is required");
			setErrorconfirm_password("Confirm password is required");
		} else if (new_password !== confirm_password) {
			setErrornew_password("New password and confirm password do not match");
			setErrorconfirm_password("New password and confirm password do not match");
		} else if (new_password.length < 8) {
			setErrornew_password("New password must be at least 8 characters long");
			setErrorconfirm_password("New password must be at least 8 characters long");
		} else if (!new_password.match(/[A-Z]/)) {
			setErrornew_password("New password must contain at least one capital letter");
			setErrorconfirm_password("New password must contain at least one capital letter");
		} else if (!new_password.match(/[0-9]/)) {
			setErrornew_password("New password must contain at least one number");
			setErrorconfirm_password("New password must contain at least one number");
		} else if (!new_password.match(/[!@#$%^&*]/)) {
			setErrornew_password("New password must contain at least one special character");
			setErrorconfirm_password("New password must contain at least one special character");

		} else {
			axios
				.post(ResetPasswordEndpoint, { current_password, new_password, confirm_password },
					{
						headers: {
						  'X-CSRFToken': Cookies.get('csrftoken'),
						  'Authorization': Cookies.get('authtoken')
						}
					  }
					)
				.then((response) => {
					setSuccessMessage(response.data.detail);
					setcurrent_password('');
					setnew_password('');
					setconfirm_password('');
					setErrorcurrent_password('');
					setErrornew_password('');
					setErrorconfirm_password('');
					// dispatch(signInRejected()); 
					// DeleteToken();
					// history.push(LoginRoute)
					
				})
				.catch((error) => {
					console.log(error)
					setErrorcurrent_password(error.response.data.current_password);
					setErrornew_password(error.response.data.new_password);
					setErrorconfirm_password(error.response.data.confirm_password);
				});
		}

	}
	return (
		<MemberBox
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "start",
			}}
		>
			<Grid container justifyContent="center" style={{ maxWidth: "420px", margin : "10px"}}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<LabelStyle>Current Password</LabelStyle>
					<LabelHintsTextStyle>
						{errorcurrent_password}
					</LabelHintsTextStyle>
					<TextField
						name="current_password"
						type="password"
						variant="outlined"
						placeholder="Current password ..."
						value={current_password}
						onChange={(e) => {
							setcurrent_password(e.target.value);
						}}
						InputProps={{
							style: {
								borderRadius: "8px",
								marginBottom: "40px",
							},
						}}
						fullWidth
					/>
					<LabelStyle>New Password</LabelStyle>
					<LabelHintsTextStyle>
						{errornew_password}
					</LabelHintsTextStyle>
					<TextField
						type="password"
						name="new_password"
						variant="outlined"
						placeholder="New password ..."
						value={new_password}
						onChange={(e) => {
							setnew_password(e.target.value);
						}}
						InputProps={{
							style: {
								borderRadius: "8px",
								marginBottom: "40px",
							},
						}}
						fullWidth
					/>
					<LabelStyle>Confirm New Password</LabelStyle>
					<LabelHintsTextStyle>
						{errorconfirm_password}
					</LabelHintsTextStyle>
					<TextField
						type="password"
						name="confirm_password"
						variant="outlined"
						placeholder="Confirm New Password ..."
						value={confirm_password}
						onChange={(e) => {
							setconfirm_password(e.target.value);
						}}
						InputProps={{
							style: {
								borderRadius: "8px",
								marginBottom: "40px",
							},
						}}
						fullWidth
					/>
					<LabelHintsTextStyle>
						{successMessage}
					</LabelHintsTextStyle>
                    					<button
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							padding: "20px",
							gap: "4px",
							height: "64px",
							background: "#070FB7",
							borderRadius: "10px",
							flex: "none",
							order: 0,
							flexGrow: 0,
							border: "none", // Remove default button border
							color: "white", // Text color
							fontSize: "16px", // Text size
							cursor: "pointer", // Change cursor on hover
						}}
						onClick={resetPasswordHandler}
						// Optional onClick event handler
					>
						<LabelStyleButton>Change Password</LabelStyleButton>
					</button>
				</Grid>
			</Grid>
		</MemberBox>
	);
}

export default ChangePasswordBox;
