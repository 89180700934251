// authReducer.js
const initialState = {
    isAuthenticated: false,
    authStateChecking: true,
  };
  
  const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SIGN_IN_FULFILLED':
        return {
          ...state,
          isAuthenticated: true,
          authStateChecking: false,
        };
      case 'SIGN_IN_FAILED':
        return {
          ...state,
          isAuthenticated: false,
          authStateChecking: false,
        };
      default:
        return state;
    }
  };
  
  export default AuthReducer;
  