import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const AuthRoute = ({ component:Component, isAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routerProps => {
        return (
          !isAuth
            ? <Component {...routerProps} />
            : <Redirect
                to={{ pathname: "/" }}
              />
        )
      }}
    />
  )
}

export default AuthRoute