import React, { Component} from "react";

class Home extends Component {

    constructor(){
        super();
    }
    render(){
        return(
            <div>
                
                <h1>this is home page new</h1>
            </div>
        )
    }

}

export default Home;