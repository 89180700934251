import React, { useState, useEffect } from "react";
import {
	GradientBackground,
	StyledGrid,
} from "../components/style/BackgroundStyle";
import Logo from "../components/logo/Logo";
import { Grid } from "@material-ui/core";
import { SideTitle } from "../components/style/TextStylesTitle";
import ResponsiveMembershipBox from "../components/membership/MembershipBox";
import axios from "axios";
import { MembershipDataEndpoint } from "../components/Endpoints";
import { PlanModel } from "../components/Models";

function Membership() {
	const [membershipdata, setMembershipdata] = useState([]);
	useEffect(() => {
		const fetchMembershipdata = async () => {
			try {
				const response = await axios.get(MembershipDataEndpoint);
				const data = response.data.map(
					(membershipplan) =>
						new PlanModel(
							membershipplan.id,
							membershipplan.name,
							membershipplan.description,
							membershipplan.price,
							membershipplan.features,
							membershipplan.type
						)
				);
				setMembershipdata(data);
			} catch (error) {
				console.error("Error fetching tutors:", error);
			}
		};

		fetchMembershipdata();
	}, []);
	return (
		<GradientBackground>
			<StyledGrid item xs={12} sm={12} md={12}>
				<Grid item style={{ paddingBottom: "50px" }}>
					<Logo />
				</Grid>
				<Grid item style={{ textAlign: "center", paddingBottom: "43px" }}>
					<SideTitle>Join Math tutor Premium</SideTitle>
				</Grid>
				<Grid item style={{ textAlign: "center" }}>
					<ResponsiveMembershipBox membershipdata={membershipdata} key="member-data"/>
				</Grid>
			</StyledGrid>
		</GradientBackground>
	);
}

export default Membership;
