import React , { useState, useEffect } from "react";
import {  } from "./MemberStyle";
import { Grid } from "@material-ui/core";
import { UserInfoEndpoint } from "../Endpoints";
import axios from 'axios';


function UserInfoBox() {
    const [email, setEmail ] = useState('mail@address.com');
    const [joinDate, setJainDate]  = useState('15.05.2023');
    const [error, setError] =useState('');
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(UserInfoEndpoint); // Change the URL to your API endpoint
            setEmail(response.data.email);
            setJainDate(response.data.date_joined);
          } catch (error) {
            setError(error.message);
          }
        };
    
        fetchData();
      }, []);
    
      if (error) {
        return <div>Error: {error}</div>;
      }

    return (
<div style={{ margin: "auto", maxWidth: "743px" }}>

        <Grid container  justifyContent="space-around" alignContent="center" alignItems="center"  spacing={3} style={{
            //  maxWidth: "703px",
            //  margin: "auto",
             textAlign: "center", // Center the content horizontally
             background : "#edeaf4",
             borderRadius : "16px",
             margin : "20px",
             width : "auto",

             padding : "10px"
        }}>

     
            <Grid item>
                <Grid style={{textAlign : "start"}}>Account email address:</Grid>
                <Grid style={{textAlign : "start"}}>{email}</Grid>
            
            
            </Grid>
            <Grid item>
                <Grid style={{textAlign : "start"}}>Member since:</Grid>
                <Grid style={{textAlign : "start"}}>{joinDate}</Grid>
            </Grid>
    
        </Grid>
        </div>
    );
}

export default UserInfoBox;