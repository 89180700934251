import React from "react";

import Logo from '../components/logo/Logo';
import Grid from '@material-ui/core/Grid';
import ResponsiveLoginBox from "../components/login/LoginBox";
import {GradientBackground , StyledGrid} from '../components/style/BackgroundStyle';
import {SideTitle} from "../components/style/TextStylesTitle";




// Define a styled component with linear gradient background






function Login() {
  return (
    <GradientBackground>
      <Grid item container  justifyContent="center" alignItems="center">
      {/* Each Grid item represents a column */}
      <StyledGrid item xs={12} sm={4} md={4} >
      <Grid item style={{ paddingBottom: '50px' }}>
          <Logo />
        </Grid>
        <SideTitle>Login to</SideTitle> <br /><SideTitle>your account</SideTitle>
      </StyledGrid>
      <StyledGrid item xs={12} sm={4} md={4}>
        {/* Content for the second column */}
        <ResponsiveLoginBox/>
      </StyledGrid>
    </Grid>
    </GradientBackground>
  );
}

export default Login;
