import React from "react";
import {IndicatorPageBoxStyle, IndicatorPageBoxTitleStyle, IconColor} from "../../components/member/MemberStyle"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from "@mui/material";
import { useHistory } from 'react-router-dom';
import Home from "../../pages/Home";
import { HomeRoute } from "../routerpaths";

function IndicatorPageLink({pageName}) {
    const history = useHistory();
    const handleClick = () => {
        // Navigate to another component
        history.push(HomeRoute);
      };
    return (<IndicatorPageBoxStyle><Button onClick={handleClick}><IndicatorPageBoxTitleStyle > <ArrowBackIosIcon color={IconColor} /> {pageName}</IndicatorPageBoxTitleStyle></Button></IndicatorPageBoxStyle>);
}

export default IndicatorPageLink;