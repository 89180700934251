import React from "react";
import Grid from "@material-ui/core/Grid";
import Logo from "../../components/logo/Logo";
import { GradientBackground } from "../../components/style/BackgroundStyle";
import AccountBox from "../../components/member/AccountBox";
import SideBox from "../../components/member/sidebox";

function Subscription() {
	return (
		<GradientBackground>
			<Grid item container justifyContent="flex-start" alignItems="flex-start">
				{/* Each Grid item represents a column */}
				<Grid item xs={12} sm={12} md={3}>
					<Grid item container>
						<Grid xs={12} sm={12} md={12} item style={{ paddingBottom: "50px" }}>
							<Logo />
						</Grid>
						<Grid xs={12} sm={12} md={12} item style={{"padding" : "20px" , "marginRight" : "20px"}}>
							< SideBox pagename="Subscription Settings" isActiveSubscription={true}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={9}>
					<AccountBox />
				</Grid>
			</Grid>
		</GradientBackground>
	);
}

export default Subscription;
