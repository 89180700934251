import React from "react";
import Grid from "@material-ui/core/Grid";
import PaidPlanDetails from "./PaidBox";
import FreePlanDetails from "./FreeBox";
import useMediaQuery from "@material-ui/core/useMediaQuery";
function MembershipBox({ membershipdata }) {
	const isMobile = useMediaQuery("(max-width:1279px)");
	const items = isMobile ? [...membershipdata].reverse() : membershipdata;
	return (
		<Grid
			container
			spacing={4}
			key="boxes-plan"
			direction="row"
			justifyContent="center"
			alignItems="stretch"
		>
			{items.map((membershipPlan) => (
				<React.Fragment key={membershipPlan.id}>
					{membershipPlan.type === "free" ? (
						<Grid item key={membershipPlan.id} xs={12} sm={12} md={12} lg={4} style={{ height: "100%" }}>
							<FreePlanDetails
								name={membershipPlan.name}
								description={membershipPlan.description}
								price={membershipPlan.price}
								features={membershipPlan.features}
							/>
						</Grid>
					) : (
						<Grid item key={membershipPlan.id} xs={12} sm={12} md={12} lg={8} style={{ height: "100%" }}>
							<PaidPlanDetails
								name={membershipPlan.name}
								description={membershipPlan.description}
								price={membershipPlan.price}
								features={membershipPlan.features}
							/>
						</Grid>
					)}
				</React.Fragment>
			))}
		</Grid>
	);
}

export default function ResponsiveMembershipBox({ membershipdata }) {
	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			key="membership-grid-data"
		>
			<Grid item xs={12} sm={12} md={8} lg={8}>
				<MembershipBox membershipdata={membershipdata} key="data-set" />
			</Grid>
		</Grid>
	);
}
