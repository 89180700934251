import styled from "styled-components";
import InputBase from '@material-ui/core/InputBase';

export const LabelStyle = styled.div`
/* Label */
/* Large - Semibold */
height : 28px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 155%;
/* identical to box height, or 28px */

/* Gray/Gray 9 */
color: #212529;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;

`;
export const LabelStyleLink = styled.a`
/* Feature label goes h */


font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 22px;
/* identical to box height */
text-decoration-line: underline;

color: #070FB7;


/* Inside auto layout */
flex: none;
order: 2;
flex-grow: 0;`;

export const LabelStyleButton = styled.div`
/* Login */

height: 24px;

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height */
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;

`;
export const LabelStyleFeatured = styled.span`
/* Feature label goes h */

height: 23px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;
/* identical to box height */

color: #070FB7;


/* Inside auto layout */
flex: none;
order: 2;
flex-grow: 0;


`
export const LabelStyleFeaturedLink = styled.span`
height: 23px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;
/* identical to box height */

color: #070FB7;
/* Inside auto layout */
flex: none;
order: 2;
flex-grow: 0;
text-decoration: underline;
`;


export const HorizantalLine = styled.div`
    height: 1px;
    background: #070FB7;
    opacity: 0.2;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom : 10px;
`;

export const LabelHintsTextStyle = styled.span`
/* Medium  */
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 155%;
/* or 25px */

/* Gray/Gray 6 */
color: #868E96;
margin-top:20;

`;