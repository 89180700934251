// Router.js
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import RouteController from "./utility/ RouteController";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Tutor from "./pages/Tutor";
import Membership from "./pages/Membership";
import AccountDetails from "./pages/members/AccountDetails";
// import Logout from "./pages/members/Logout";
import ChangePassword from "./pages/members/ChangePassword";
import Subscription from "./pages/members/Subscription";

import {
	HomeRoute,
	LoginRoute,
	RegisterRoute,
	TutorRoute,
	MembershipRoute,
	ProfileRoute,
	SubscriptionRoute,
	ChangePasswordRoute,
	LogoutRoute,
} from "./components/routerpaths";

import NotFoundException from "./pages/NotFound";

function AppRouter({ isAuth }) {
	return (
		<Router>
			<Switch>
				<RouteController
					routeType={"protected"}
					isAuth={isAuth}
					exact
					component={Home}
					path={HomeRoute}
				/>
				<RouteController
					routeType={"auth"}
					isAuth={isAuth}
					component={Login}
					path={LoginRoute}
				/>
				<RouteController
					routeType={"auth"}
					isAuth={isAuth}
					component={Register}
					path={RegisterRoute}
				/>
				<RouteController
					routeType={"public"}
					isAuth={isAuth}
					component={Tutor}
					path={TutorRoute}
				/>
				<RouteController
					routeType={"public"}
					isAuth={isAuth}
					component={Membership}
					path={MembershipRoute}
				/>

				{/* after login router */}
				<RouteController
					routeType={"protected"}
					isAuth={isAuth}
					component={AccountDetails}
					path={ProfileRoute}
				/>
				<RouteController
					routeType={"protected"}
					isAuth={isAuth}
					component={ChangePassword}
					path={ChangePasswordRoute}
				/>
				<RouteController
					routeType={"protected"}
					isAuth={isAuth}
					component={Subscription}
					path={SubscriptionRoute}
				/>
				{/* <RouteController
					routeType={"protected"}
					isAuth={isAuth}
					component={Logout}
					path={LogoutRoute}
				/> */}
				<Route component={NotFoundException} />
			</Switch>
		</Router>
	);
}

export default AppRouter;
