// appStore.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Import the rootReducer

const store = configureStore({
  reducer: rootReducer,
  // Add any other store configurations here if needed
});

export default store;
