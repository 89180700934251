import React from "react";
import styled from 'styled-components';
import Logo from '../components/logo/Logo';
import Grid from '@material-ui/core/Grid';
import {GradientBackground , StyledGrid} from '../components/style/BackgroundStyle';
import {SideTitle, Sidesubtitle, StyledGridItem, StyledGridItemTitle} from "../components/style/TextStylesTitle";
import {CheckboxStyled} from "../components/style/CheckboxStyle";
import ResponsiveRegisterBox from "../components/register/RegisterBox";
import Hidden from '@mui/material/Hidden';



function Register() {
  return (
    <GradientBackground>
      <Grid item container  justifyContent="center" alignItems="center">
      {/* Each Grid item represents a column */}
      <StyledGrid item xs={12} sm={4} md={4} >
        <Grid item style={{ paddingBottom: '50px' }}>
            <Logo />
          </Grid>
        <StyledGridItemTitle item>
        <SideTitle>Get started</SideTitle><br /><SideTitle>for free</SideTitle>
        </StyledGridItemTitle>

        <Hidden smDown>
        <StyledGridItem item>
        <CheckboxStyled/>
        <Sidesubtitle>Feature label goes here</Sidesubtitle>
        </StyledGridItem>

        <StyledGridItem item>
        <CheckboxStyled/>
        <Sidesubtitle>Feature label goes here</Sidesubtitle>
        </StyledGridItem>

        <StyledGridItem item>
        <CheckboxStyled/>
        <Sidesubtitle>Feature label goes here</Sidesubtitle>
        </StyledGridItem>

        <StyledGridItem item>
        <CheckboxStyled/>
        <Sidesubtitle>Feature label goes here</Sidesubtitle>
        </StyledGridItem>

        </Hidden>


      </StyledGrid>
      <StyledGrid item xs={12} sm={4} md={4}>
        <ResponsiveRegisterBox/>
      </StyledGrid>
    </Grid>
    </GradientBackground>
  );
}

export default Register;
