export class TutorModel {
    constructor(id, name, color, photo, description) {
        this.id = id
        this.color = color
        this.name = name;
        this.photo = photo;
        this.description = description;
    }
}

export class TutorModelLoggedIn {
    constructor(id, name, color, photo, flag, description) {
        this.id = id
        this.color = color
        this.name = name;
        this.photo = photo;
        this.flag = flag;
        this.description = description;
    }
}


export class PlanModel {
    constructor(id, name, description, price, features, type) {
        this.id = id
        this.name = name;
        this.description = description;
        this.price = price;
        this.features = features;
        this.type = type;
    }
}