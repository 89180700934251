import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import styled from "styled-components";
export const CheckboxStyled =  () => (
  <span style={{ display: 'inline-block', paddingLeft: 0 }}>
    <CheckIcon style={{
      opacity: 0.5,
      height: 16,
      fontSize: 40,
      transform: 'scale(1.3)',
      mixBlendMode: 'normal',
    }} />
  </span>
);



export const ListItem = styled.li`
  list-style-type: none; /* Remove default list styling */
  display: flex;
`;

export const PackagemonthStyle = styled.span`
/* Unlimited */
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 60px;
/* identical to box height */

color: #FFFFFF;


`;