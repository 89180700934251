import React from "react";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
	LabelStyle,
	LabelStyleLink,
	LabelStyleButton,
	LabelStyleFeatured,
	LabelStyleFeaturedLink,
	HorizantalLine,
	LabelHintsTextStyle,
} from "../style/LoginStyles";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { CSRFTokenEndpoint, RegisterEndpoint } from "../Endpoints";

function RegisterBox() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [emailError, setEmailError] = useState("");
  const [registeredFlag , setregisteredFlag]  = useState("");

	useEffect(() => {
		const getCsrfToken = async () => {
			try {
				const response = await axios.get(CSRFTokenEndpoint, {
					withCredentials: true,
				});
			} catch (error) {
				console.error("Error fetching CSRF token:", error);
			}
		};

		getCsrfToken();
	}, []);
	const register = async (e) => {
		e.preventDefault();
		try {
			const res = await axios.post(
				RegisterEndpoint,
				{
					email,
					password,
				},
				{
					withCredentials: true,
					headers: {
						"X-CSRFToken": Cookies.get("csrftoken"),
					},
				}
			);
			setregisteredFlag(res.data.message);
		} catch (error) {
			setPasswordError(error.response.data.password[0]);
			setEmailError(error.response.data.email[0]);
		}
	};
	return (
		<Paper style={{ padding: "40px", borderRadius: "16px" }}>
			<Grid container spacing={2} direction="column">
				{/* Email Address Label and Input */}
				<Grid item>
					<LabelStyle>Email Address</LabelStyle>
					<TextField
						variant="outlined"
						placeholder="mail@address.com"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						InputProps={{
							style: {
								borderRadius: "8px",
								marginBottom: "27px",
							},
						}}
						fullWidth
					/>
          				<Grid item>
					<LabelHintsTextStyle>{emailError}</LabelHintsTextStyle>
				</Grid>
				</Grid>

				{/* Password Label and Input */}
				<Grid item>
					<LabelStyle>Password</LabelStyle>
					{/* <Grid item>
						<LabelHintsTextStyle>
							Must contain at least one capital later, one number and a special
							character
						</LabelHintsTextStyle>
					</Grid> */}
					<TextField
						type="password"
						variant="outlined"
						placeholder="Type your password..."
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
						InputProps={{
							style: {
								borderRadius: "8px",
								marginBottom: "5px",
							},
						}}
						fullWidth
					/>
				</Grid>
				<Grid item>
					<LabelHintsTextStyle>{passwordError}</LabelHintsTextStyle>
				</Grid>
        <Grid item>
					<LabelHintsTextStyle>{registeredFlag}</LabelHintsTextStyle>
				</Grid>
				<Grid item>
					<button
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							padding: "20px",
							gap: "4px",
							height: "64px",
							background: "#070FB7",
							borderRadius: "10px",
							flex: "none",
							order: 0,
							flexGrow: 0,
							border: "none", // Remove default button border
							color: "white", // Text color
							fontSize: "16px", // Text size
							cursor: "pointer", // Change cursor on hover
						}}
						onClick={register}
					>
						<LabelStyleButton>Create account</LabelStyleButton>
					</button>
				</Grid>

				<Grid item>
					<HorizantalLine />
					<Typography variant="body2">
						<LabelStyleFeatured>Already have an account? </LabelStyleFeatured>
						<Link to="/login">
							<LabelStyleFeaturedLink>Login</LabelStyleFeaturedLink>
						</Link>
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default function ResponsiveRegisterBox() {
	return (
		<Grid container spacing={2} justifyContent="center">
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<RegisterBox />
			</Grid>
		</Grid>
	);
}
