import React from "react";
import Paper from "@material-ui/core/Paper";
import {TutorNamesStyle, TutorDescriptionStyle} from "../style/TextStylesTitle";
import {LabelStyleButton} from "../style/LoginStyles";


function GetDetailsBox({name, color, photo, description }){
    return (<Paper elevation={3} style={{
        display: 'inline-block', 
        margin : '15px',
        marginTop: '20px',
        padding : '20px',
        // maxWidth : '296px',
        // minHeight : '504px',
        background : `linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), ${color}`,
        borderRadius: '16px',
        textAlign : 'start'
        }}>
            <div><img src={photo} alt={name} style={{
                width: '120px',
                height : '120px',  
                opacity: 0.8,
                borderRadius: "100%",}}/></div>
            <TutorNamesStyle>{name}</TutorNamesStyle>
            <TutorDescriptionStyle>{description}</TutorDescriptionStyle>
            <button
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px',
        marginBottom : '20px',
        gap: '4px',
        height: '64px',
        borderRadius: '10px',
        flex: 'none',
        background : 'transparent',
        order: 0,
        marginTop : '40px',
        flexGrow: 0,
        border: 'none', // Remove default button border
        color: 'white', // Text color
        fontSize: '16px', // Text size
        cursor: 'pointer', // Change cursor on hover
        borderRadius : '10px',
        border : '1px solid #564F5C'
      }}
       // Optional onClick event handler
    ><LabelStyleButton style={{ color: '#564F5C'}}>Login</LabelStyleButton></button>
            </Paper>);
}




export default GetDetailsBox;