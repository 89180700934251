import React, { useState, useEffect } from "react";
import { MemberBox } from "./MemberStyle";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import UserInfoBox from "./UserInfoBox";
import { TutorModelLoggedIn } from "../Models";
import { TutorEndpoint } from "../Endpoints";
import axios from "axios";
import { SideTitle } from "../style/TextStylesTitle";
import { StyledGrid } from "../style/BackgroundStyle";
import Paper from "@material-ui/core/Paper";
import IndicatorPageLink from "./IndicatorPageBox";
import {
	TutorNamesStyle,
	TutorDescriptionStyle,
} from "../style/TextStylesTitle";
import { LabelStyleButton } from "../style/LoginStyles";
import { TutorSelectionEndpoint } from "../Endpoints";
import { CSRFTokenEndpoint } from "../Endpoints";
import Cookies from "js-cookie";
function GetDetailsBoxMember({ id, name, color, photo, flag, description }) {
	useEffect(() => {
		const getCsrfToken = async () => {
			try {
				await axios.get(CSRFTokenEndpoint, {
					withCredentials: true,
				});
			} catch (error) {
				console.error("Error fetching CSRF token:", error);
			}
		};

		getCsrfToken();
	}, []);

	const handleSelectionEvent = async (event) => {
		const value = event.currentTarget.getAttribute("data-value");

		try {
			const csrftoken = Cookies.get("csrftoken"); // Function to get CSRF token from cookies
			const response = await axios.post(
				TutorSelectionEndpoint, // Replace with your actual endpoint URL
				{ tutor_id: value }, // Data to send in the request body
				{
					headers: {
						"Content-Type": "application/json", // Set content type header
						"X-CSRFToken": csrftoken, // Set CSRF token header
					},
					withCredentials: true, // Include credentials (cookies) in the request
				}
			);
			// Handle response
			console.log(response.data);
			window.location.reload();
		} catch (error) {
			// Handle error
			console.error("Error updating tutor selection:", error);
		}
	};

	return (
		<Paper
			elevation={0}
			style={{
				display: "inline-block",
				margin: "15px",
				marginTop: "20px",
				padding: "20px",
				// maxWidth : '296px',
				// minHeight : '504px',
				background: `linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), ${color}`,
				border: flag ? "2px solid #070FB7" : null,
				borderRadius: "16px",
				textAlign: "start",
			}}
		>
			<div>
				<img
					src={photo}
					alt={name}
					style={{
						width: "120px",
						height: "120px",
						opacity: 0.8,
						borderRadius: "100%",
					}}
				/>
			</div>
			<TutorNamesStyle>{name}</TutorNamesStyle>
			<TutorDescriptionStyle>{description}</TutorDescriptionStyle>
			{flag ? (
				<button
					disabled
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						padding: "20px",
						marginBottom: "20px",
						gap: "4px",
						height: "64px",
						borderRadius: "10px",
						flex: "none",
						background: "rgba(0, 0, 0, 0.1)",
						order: 0,
						marginTop: "40px",
						flexGrow: 0,
						border: "none", // Remove default button border
						color: "white", // Text color
						fontSize: "16px", // Text size
						cursor: "pointer", // Change cursor on hover
						borderRadius: "30px",
						//border: "1px solid #564F5C",
					}}
					// Optional onClick event handler
				>
					<LabelStyleButton style={{ color: "#564F5C" }}>
						Your current tutor
					</LabelStyleButton>
				</button>
			) : (
				<button
					data-value={id}
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						padding: "20px",
						marginBottom: "20px",
						gap: "4px",
						height: "64px",
						borderRadius: "10px",
						flex: "none",
						background: "transparent",
						order: 0,
						marginTop: "40px",
						flexGrow: 0,
						border: "none", // Remove default button border
						color: "white", // Text color
						fontSize: "16px", // Text size
						cursor: "pointer", // Change cursor on hover
						borderRadius: "10px",
						border: "1px solid #564F5C",
					}}
					// Optional onClick event handler
					onClick={handleSelectionEvent}
				>
					<LabelStyleButton style={{ color: "#564F5C" }}>
						Select
					</LabelStyleButton>
				</button>
			)}
		</Paper>
	);
}

function ResponsiveTutorBoxMember({ tutors }) {
	return (
		<React.Fragment>
			<Grid container spacing={1} justifyContent="center">
				{tutors.map((tutor) => (
					<Grid item key={tutor.id} xs={12} sm={12} md={12} lg={4} xl={3}>
						<GetDetailsBoxMember
							key={tutor.id}
							id={tutor.id}
							color={tutor.color}
							name={tutor.name}
							photo={tutor.photo}
							flag={tutor.flag}
							description={tutor.description}
						/>
					</Grid>
				))}
			</Grid>
		</React.Fragment>
	);
}

function TutorMember() {
	const [tutors, setTutors] = useState([]);
	useEffect(() => {
		const fetchTutors = async () => {
			try {
				const response = await axios.get(TutorEndpoint);
				const data = response.data.map(
					(tutor) =>
						new TutorModelLoggedIn(
							tutor.id,
							tutor.name,
							tutor.color,
							tutor.photo,
							tutor.is_selected_by_user,
							tutor.description
						)
				);
				setTutors(data);
			} catch (error) {
				console.error("Error fetching tutors:", error);
			}
		};

		fetchTutors();
	}, []);
	return (
		<StyledGrid item xs={12} sm={12} md={12}>
			<Grid item style={{ textAlign: "center", color: "#564F5C" }}>
				<SideTitle style={{ textAlign: "center", color: "#564F5C" }}>
					Select your Tutor
				</SideTitle>
			</Grid>
			<Grid item style={{ textAlign: "center", color: "#564F5C" }}>
				<ResponsiveTutorBoxMember tutors={tutors} />
			</Grid>
		</StyledGrid>
	);
}

function AccountBox() {
	return (
		<MemberBox>
			<Grid container alignItems="center" justifyContent="center">

				<Grid item xs={12} sm={12} md={12}>
					<UserInfoBox />
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<TutorMember />
				</Grid>
			</Grid>
		</MemberBox>
	);
}

export default AccountBox;
