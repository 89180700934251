import React from "react";
import Logo from "../../components/logo/Logo";
import { GradientBackgroundMember , GradientBackgroundColorcode} from "../../components/style/BackgroundStyle";
import IndicatorPageLink from "../../components/member/IndicatorPageBox";
import ChangePasswordBox from "../../components/member/ChangePasswordBox";
import SideBox from "../../components/member/sidebox";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
function ChangePassword() {
	const theme = useTheme();
	const isXsOrSm = useMediaQuery(theme.breakpoints.down("sm"));
	const PageName = "Change Password";
	return (
		<GradientBackgroundMember style={{
			background: isXsOrSm ?  "#ffffff"  : GradientBackgroundColorcode
		}}>
			<Grid item container justifyContent="flex-start" alignItems="flex-start">
				{/* Each Grid item represents a column */}
				{isXsOrSm ? null : (
					<Grid item xs={12} sm={12} md={3}>
						<Grid item container>
							<Grid
								xs={12}
								sm={12}
								md={12}
								item
								style={{ paddingBottom: "50px" }}
							>
								<Logo />
							</Grid>
							<Grid
								xs={12}
								sm={12}
								md={12}
								item
								style={{ padding: "20px", marginRight: "20px" }}
							>
								<SideBox
									pagename={PageName}
									isActiveChangePassword={true}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}

				{
					isXsOrSm ? (
						<Grid item xs={12} sm={12} md={12}>
							<IndicatorPageLink pageName={PageName} />
						</Grid>
					) : null
				}
				<Grid item xs={12} sm={12} md={9}>
					<ChangePasswordBox />
				</Grid>
			</Grid>
		</GradientBackgroundMember>
	);
}

export default ChangePassword;
