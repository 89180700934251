import React, { useState, useEffect } from "react";
import {GradientBackground , StyledGrid} from '../components/style/BackgroundStyle';
import Logo from '../components/logo/Logo';
import { Grid } from "@material-ui/core";
import {SideTitle} from "../components/style/TextStylesTitle";
import ResponsiveTutorBox  from "../components/tutor/TutorBox";
import axios from 'axios';
import { TutorEndpoint } from "../components/Endpoints";
import {TutorModel}  from "../components/Models"


function Tutor() {
    const [tutors, setTutors] = useState([]);
    useEffect(() => {
      const fetchTutors = async () => {
          try {
              const response = await axios.get(TutorEndpoint);
              const data = response.data.map(tutor => new TutorModel(tutor.id, tutor.name, tutor.color,tutor.photo, tutor.description));
              setTutors(data);
          } catch (error) {
              console.error('Error fetching tutors:', error);
          }
      };

      fetchTutors();
  }, []);
    return (
      <GradientBackground>
      <StyledGrid item xs={12} sm={12} md={12}>
        <Grid item style={{ paddingBottom: '50px' }}>
            <Logo />
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
                    <SideTitle>Select your Tutor</SideTitle>
                </Grid>
          <Grid item style={{ textAlign: 'center' }}>
                    <ResponsiveTutorBox tutors={tutors}  />
                </Grid>
        
      </StyledGrid>
      </GradientBackground>
    );
}

export default Tutor;