import React from "react";
import Paper from "@material-ui/core/Paper";
import {
	MembershipPlanTitleStyle,
	MembershipPlanSubtitleStyle,
	MembershipPlanFeaturesStyle,
} from "../style/TextStylesTitle";
import { LabelStyleButton } from "../style/LoginStyles";
import CheckIcon from "@mui/icons-material/Check";
import { ListItem } from "../style/CheckboxStyle";
import { Grid } from "@mui/material";
import Box from '@material-ui/core/Box';

function FreePlanDetails({ name, description, price, features }) {
	return (
		<Box style={{
			// display: "inline-block",
			paddingLeft: "40px",
			paddingTop: "20px",
			paddingRight: "40px",
			paddingBottom: "30px",
			// maxWidth : '336px',
			minHeight : '504px',
			background: "#F5EDDE",
			borderRadius: "16px",
			textAlign: "start",
		}} p={2}>
			<Grid
				container
				spacing={1}
				rowspacing={1}
				direction="row"
				justifyContent="center"
				alignItems="center"
				style={{ marginBottom: "12px" }}
			>
				<Grid item xs={12} sm={12} md={12}>
					<MembershipPlanTitleStyle style={{ color: " #564F5C" }}>
						{name}
					</MembershipPlanTitleStyle>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<MembershipPlanSubtitleStyle
						style={{ color: "#564F5C", paddingBottom: "57px" }}
					>
						{description}
					</MembershipPlanSubtitleStyle>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={0}
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				{features.map((feature, index) => (
					<Grid key={index} item xs={12} sm={12}>
						<ListItem key={index}>
							<CheckIcon style={{ color: "#4E2D92", paddingRight: "12px" }} />
							<MembershipPlanFeaturesStyle style={{ color: "#564F5C" }}>
								{feature}
							</MembershipPlanFeaturesStyle>
							{/* Use the styled component here */}
						</ListItem>
					</Grid>
				))}
			</Grid>
			<button
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					padding: "20px",
					marginBottom: "20px",
					gap: "4px",
					height: "64px",
					borderRadius: "10px",
					flex: "none",
					background: "transparent",
					order: 0,
					marginTop: "40px",
					flexGrow: 0,
					border: "none", // Remove default button border
					color: "white", // Text color
					fontSize: "16px", // Text size
					cursor: "pointer", // Change cursor on hover
					borderRadius: "10px",
					border: "1px solid #564F5C",
				}}
				// Optional onClick event handler
			>
				<LabelStyleButton style={{ color: "#564F5C" }}>
					Start with Free
				</LabelStyleButton>
			</button>
		</Box>
	);
}

export default FreePlanDetails;
