import React from 'react';
import Typography from '@material-ui/core/Typography';

function Logo() {
  return (
    <div style={{ position: 'absolute', top: 20, left: 20, zIndex: 999}}>
      <Typography variant="h6" style={{ fontFamily: 'Work Sans', color: '#FFFFFF' }}>
        <span style={{ fontWeight: 700 }}>Math</span>
        <span style={{ fontWeight: 400 }}>Tutor</span>
      </Typography>
    </div>
  );
}

export default Logo;
