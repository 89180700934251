import React from "react";
import {
	SideBarBox,
	SidebarTextStyle,
	SidebarTextActive,
	SidebarTextNotActive,
} from "./MemberStyle";
import {
	ProfileRoute,
	ChangePasswordRoute,
	SubscriptionRoute,
	LogoutRoute,
	LoginRoute
} from "../routerpaths";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from "axios";
import {signInRejected} from "../../actions"
import { LogoutEndpoint } from "../Endpoints";

function SideBox({
	isActiveAccount,
	isActiveSubscription,
	isActiveChangePassword,
}) {
	const history = useHistory();
    const dispatch = useDispatch();
	const handleLogout = () => {
		axios.get(LogoutEndpoint) // Replace '/api/logout' with your logout endpoint
            .then(response => {
				dispatch(signInRejected());
				Cookies.remove('authtoken');
				window.location.replace(LoginRoute)
            })
            .catch(error => {
                console.error('Error logging out:', error);
                // Still redirect to login page even if there's an error
                //history.push(LoginRoute);
            });

	}

	return (
		<div>
			<SideBarBox>
				{isActiveAccount ? (
					<SidebarTextActive>
						<Link to={ProfileRoute} style={{ paddingBottom: 10 }}>
							<SidebarTextStyle>Account details</SidebarTextStyle>
						</Link>
					</SidebarTextActive>
				) : (
					<SidebarTextNotActive>
						<Link to={ProfileRoute} style={{ paddingBottom: 10 }}>
							<SidebarTextStyle>Account details</SidebarTextStyle>
						</Link>
					</SidebarTextNotActive>
				)}

				{isActiveSubscription ? (
					<SidebarTextActive>
						<Link to={SubscriptionRoute} style={{ paddingBottom: 10 }}>
							<SidebarTextStyle>Subscription Settings</SidebarTextStyle>
						</Link>
					</SidebarTextActive>
				) : (
					<SidebarTextNotActive>
						<Link to={SubscriptionRoute} style={{ paddingBottom: 10 }}>
							<SidebarTextStyle>Subscription Settings</SidebarTextStyle>
						</Link>
					</SidebarTextNotActive>
				)}

				{isActiveChangePassword ? (
					<SidebarTextActive>
						<Link to={ChangePasswordRoute} style={{ paddingBottom: 10 }}>
							<SidebarTextStyle>Change Password</SidebarTextStyle>
						</Link>
					</SidebarTextActive>
				) : (
					<SidebarTextNotActive>
						<Link to={ChangePasswordRoute} style={{ paddingBottom: 10 }}>
							{" "}
							<SidebarTextStyle>Change Password</SidebarTextStyle>
						</Link>
					</SidebarTextNotActive>
				)}
			</SideBarBox>
			<SideBarBox>
				<Link to="#" style={{ paddingBottom: 10 }} onClick={handleLogout}>
					<SidebarTextStyle>Logout</SidebarTextStyle>
				</Link>
			</SideBarBox>
		</div>
	);
}

export default SideBox;
