


export const HomeRoute = "/";
export const LoginRoute = "/login/";
export const RegisterRoute = "/register/";
export const LogoutRoute = "/logout/";
export const ForgotPasswordRoute = "/forgot-password/";

export const TutorRoute = "/tutor/";
export const MembershipRoute = "/membership/";
//after login routes
export const ProfileRoute = "/member/profile/";
export const ChangePasswordRoute = "/member/change-password/";
export const SubscriptionRoute = "/member/subscription/";
